<template>
  <NuxtLink v-if="to" :to="localePath((to as RouteLocation), locale)">
    <slot></slot>
  </NuxtLink>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type {
  RouteLocationPathRaw,
  RouteLocationNamedRaw,
  RouteLocation
} from 'vue-router'

withDefaults(
  defineProps<{
    to: string | RouteLocationPathRaw | RouteLocationNamedRaw | undefined
    locale?: string
  }>(),
  {
    locale: undefined
  }
)

const localePath = useLocalePath()
</script>
